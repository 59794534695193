.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('blerk.png') no-repeat center center fixed;
  background-size: 45%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust transparency here */
  background-blend-mode: overlay;
  position: relative;
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.text-div {
  position: absolute;
  top: 0px;
  left: 10px;
  font-size: 2.8rem;
  margin: 0;
  color: black;
}

.text-div1 {
  position: absolute;
  top: 50px;
  left: 20px;
  font-size: 28px;
  color: black;
}

.text-div2 {
  position: absolute;
  bottom: 10px;
  left: 15px;
  font-size: 28px;
  color: black;
}

.buy-button {
  padding: 20px 40px;
  font-size: 20px;
  background-color: #ff4500;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s;
  position: absolute;
  top: 100px;
  left: 20px;
}

.buy-button:hover {
  transform: scale(1.1);
}




